import { useEffect, useState } from 'react'
import axios from 'axios'

export default function TenancyColor() {
const localColor1 = localStorage.getItem('localcolor1')
    

const cor = {backgroundColor:localColor1}


  return cor;
}
