import { useEffect, useState } from 'react'
import axios from 'axios'
//COMPONENTES PARTES
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Select from 'react-select';
//INPORTAÇOES DE ICONES E IMAGENS
import IconChecked from '../../acets/img_icon/checked.svg'


import MenuPainelAdmin from '../partils/AdminMenuPainel'


export default function AdminAgenda(){
    //CONGIGURAÇÕES=============================
    const config={
        pageName:'Agenda',
        mainstring:'nome',
        url:'/api/agenda/',
        url2:'',
        search:'/api/agenda/search/',
        searchCliente:'/api/agenda/search/cliente',
        //urlOptions1:'/api/produtocategoria',
        params:'',
        messegerError:'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
     //INICIANDO CICLO DE ESTADOS
     useEffect(()=>{
        rec();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    //STATES======================================
    ////    id_leads nome	telefone  email	porte	createdAt	updatedAt	
    const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
        id_cliente:'1', dia:'', hora:'', nome:'teste', servico:'teste', status:'1', datainicio:'', datafim:''
    }
    const [openNew,setOpenNew]=useState(false)// ABRIR TELA DE CADASTRAR E EDITAR
    const [values, setValues]=useState(initialValues) // INICIANDO UM STATE

    
    const [data, setData] = useState([])
    const [date, setDate] = useState(new Date());
    const [dates, setDates] = useState(null);// range de datas para um servico
    const [dataocuped, setDataocuped] = useState([])
    const [dataCliente,setDataCliente]=useState([])
    //const occupiedDays = ['2024-10-16']; // Dias marcados como ocupados (dia 5 e 6)

    //const[dataInput,setDataInput]=useState([{"id_produto_categoria":0}])
    //FUNÇÕES=====================================
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
        axios.get(config.url).then(resp=>{
            setData(resp.data)
            console.log(data)
            setDataocuped(resp.data)
        })
        axios.get('/api/cliente/').then(resp=>
            setDataCliente(resp.data)
        )
    }

    //PESQUISA UMA DATA EM ESPECIFICO
    const handleDayClick = (date) => {
        // Formatar com zero à esquerda para o mês e o dia
        const padZero = num => num.toString().padStart(2, '0');
        const dataformated = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`;
        
        console.log(dataformated);
        axios.post(config.search, { dateparse: dataformated }).then(resp => {
            setData(resp.data);
            console.log(resp.data);
        });
    };
    

       function onSubmitSearch(ev){//PESQUISA
        ev.preventDefault();
        axios.post(config.searchCliente, values).then(respp=>{
            setData(respp.data)    
               
        })
       
    }
    //console.log(data)
    function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
        const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV
        setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
    }
    const handleSelectChangeCliente = (selectedOption) => {
        setValues({ ...values, nome: selectedOption.value });
      };
    const exclui = function(id){//EXCLUI
        axios.delete(config.url + id).then(()=>{
            rec();
            
        })
    }
    function salvar(ev) {
        //ev.preventDefault();
        // Atualiza o objeto 'values' com 'datainicio' e 'datafim' a partir de 'dates'
        const updatedValues = {
            ...values,  // Mantém os outros valores do objeto 'values'
            datainicio: dates[0],  // Define o início do intervalo
            datafim: dates[1],     // Define o fim do intervalo
        };
        // Faz a requisição POST para salvar os dados
        axios.post(config.url, updatedValues)
            .then(respp => {
                //setData(respp.data);  // Atualiza os dados com a resposta da API
                //console.log(respp.data);  // Exibe a resposta no console
                //console.log(updatedValues)
                rec();
                setOpenNew(false)
            })
            .catch(err => {
                console.error("Erro ao salvar:", err);
            });
    }
    //COMPONENTES DE LOOP==============================
    //    id_leads nome	telefone  email	porte	createdAt	updatedAt	
    
    const final = data.map((namesString) => {
        const dayOfWeek = new Date(namesString.dia + 'T00:00:00')
            .toLocaleDateString('pt-BR', { weekday: 'short' })
            .replace('.', '');
            const getDayClass = (day) => {
                switch (day) {
                    case 'seg':
                        return 'b14';  // Classe para segunda-feira
                    case 'ter':
                        return 'b25';  // Classe para terça-feira
                    case 'qua':
                        return 'b32';  // Classe para quarta-feira
                    case 'qui':
                        return 'b10';  // Classe para quinta-feira
                    case 'sex':
                        return 'b11';   // Classe para sexta-feira
                    case 'sáb':
                        return 'b9';   // Classe para sábado
                    case 'dom':
                        return 'b29';  // Classe para domingo
                    default:
                        return 'b13';  // Classe padrão (se necessário)
                }
            };
        return (
    <div className='w100 pcb7 row between linha' key={namesString.id_agenda}>
        <label className='w15  fb'>
            {new Date(namesString.dia + 'T00:00:00').toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' })} 
        </label>
        <p className={`w10 column center pcb3 cont-circ8 fs08em ${getDayClass(dayOfWeek)}`}>
            {dayOfWeek}
        </p>
        {//<label className='w10 fs08em'>{namesString.hora!='00:00:00'&&namesString.hora.slice(0,5)}</label> 
        }            
        <label className='w20 fs08em'>{namesString.nome}</label>
        <label className='w20 fs08em'>{namesString.servico}</label>
        <label className='w15 fs08em pp' onClick={()=>alert('Recurso ainda em desenvolvimento, cadastre o numero de cada cliente')}>Aviso</label>
        <label className='w15 fs08em pp'onClick={()=>exclui(namesString.id_agenda)}>Excluir</label>
    </div>
    )})
// Função para adicionar uma classe aos dias ocupados
// Função para criar um array de dias ocupados
const getOccupiedDays = (data) => {
    // Mapeia o array 'data' e retorna um array com os valores de 'dia'
    return data.map(item => item.dia);
};
//setDataocuped(data)
// Chama a função para obter o array de dias ocupados
const occupiedDays = getOccupiedDays(dataocuped);

// Função para aplicar a classe 'occupied' aos dias ocupados no calendário
const tileClassName = ({ date, view }) => {
    if (view === 'month') {
        const dateString = date.toISOString().split('T')[0]; // Converte a data para string 'YYYY-MM-DD'
        if (occupiedDays.includes(dateString)) {
            return 'occupied'; // Classe CSS para dias ocupados
        }
    }
    return null;
};

const optionInputBind = dataCliente.map(repsValues => ({
    value: repsValues.nome,
    label: repsValues.nome, // Use a propriedade apropriada para a descrição do cliente
  }));

        
          

    return(
        <>
        {openNew&&
        <div className="h100 w100 cell-tamanho row center celular posiabstop0">
                <div className='w100 h100  left0  column center z17 overflow' >
                <div className='w100 h100 posifixedinicio pcb50 fundo-opaco z5' onClick={()=>(setOpenNew(false) & setValues(initialValues))}>
                </div>
                <div className='h20 w35 b2 cont-circ3 borda1 column between z10 cell-tamanho90'>
                    <div className='w90 h20 column between'>
                        <h5 className='w90'>Agendar novos serviços</h5>
                        <Calendar
                                    onChange={setDates}
                                    value={dates}
                                    selectRange={true} // Habilita seleção de intervalo
                                    tileDisabled={({ date, view }) => view === 'month' && date < new Date()} // Desabilita datas passadas
                                    //onClickDay={handleDayClick} // Adiciona o evento de clique no dia
                                    tileClassName={tileClassName} // Adiciona a classe aos dias ocupados
                                    
                         />
                         {dates && dates.length === 2 && (
                            <>
                        <div className='w90'>
                            
                        <p className='pcb10'>
                        Serviço de: <b>{new Date(dates[0]).toLocaleDateString('pt-BR')} </b> até <b>{new Date(dates[1]).toLocaleDateString('pt-BR')}</b>
                        </p>

                            
                            <label>Selecione o cliente</label>
                            <Select options={optionInputBind} onChange={handleSelectChangeCliente} className='z1 ma5' />
                         </div>
                         <div className='w90'>
                         <label>Selecione o serviço</label>
                         <select onChange={onChange} name='servico' value={values.servico}>
                            <option value={"Alinhamento"}>Alinhamento</option>
                            <option value={"Balanceamento"}>Balanceamento</option>
                            <option value={"Suspensão"}>Suspensão</option>
                            <option value={"Injeção Eletrônica"}>Injeção Eletrônica</option>
                            <option value={"Troca de Óleo"}>Troca de Óleo</option>
                            <option value={"Revisão Completa"}>Revisão Completa</option>
                            <option value={"Freios"}>Freios</option>
                            <option value={"Troca de Pneu"}>Troca de Pneu</option>
                            <option value={"Escapamento"}>Escapamento</option>
                            <option value={"Troca de Filtro"}>Troca de Filtro</option>
                            <option value={"Bateria"}>Bateria</option>
                            <option value={"Ar Condicionado"}>Manutenção do Ar Condicionado</option>
                            <option value={"Correia Dentada"}>Substituição de Correia Dentada</option>
                            <option value={"Radiador"}>Manutenção de Radiador</option>
                            <option value={"Embreagem"}>Embreagem</option>
                            <option value={"Diagnóstico Eletrônico"}>Diagnóstico Eletrônico</option>
                            <option value={"Avaliação"}>Avaliação</option>
                            <option value={"Outro"}>Outro</option>
                        </select>

                         </div>
                         <div className='w90 row between pcb20'>
                            <button className='w40 btn05' onClick={()=>setOpenNew(false)}>Cancelar</button>
                            <button className='w40' onClick={()=>salvar()}>Salvar</button>
                         </div>
                         </>)}
                    </div>
                </div>
                
            </div>
            </div>
}
         <section className='h100 row between b31'>
            
            <MenuPainelAdmin/>
            <div className="h100 w80 cell-tamanho row center celular">
                    
                    <div className="w90 row between cell-tamanho celular">
                        <div className="h100 w100 cont-circ3  column cell-tamanho cell-paddingcb10">
                        <h5 className='w90'>Pesquisar {config.pageName}</h5>
                        <form className=' w90 row center' onSubmit={onSubmitSearch} autoComplete='off'>
                            <div className='btn10' onClick={()=>(setOpenNew(true))&setValues(initialValues)}>Add</div>
                            <div className='w60'>
                                <input type="text" name="nome" onChange={onChange} placeholder='Pesquisar cliente agendado'/>
                            </div>
                            <div className='w20'>
                                <input type="submit" value="Pesquisar" className='cell-text-smile08'/>
                            </div>
                        </form>
                        
                        <div className='w90 h20 row between  celular aligitensstart'>
                            <label className='w100 ma5 fs1p3em pcb10'>{config.pageName} Listados(a)</label>
                            
                            <div className='w40 column start cell-tamanho top0 borda1 b2' >
                                <Calendar
                                    //selectRange={true} // Habilita seleção de intervalo
                                   
                                    onClickDay={handleDayClick} // Adiciona o evento de clique no dia
                                    tileClassName={tileClassName} // Adiciona a classe aos dias ocupados
                                />
                                
                                {/* Verifica se as datas estão definidas antes de acessar toDateString() */}
                                

                                <style jsx>{`
                                    .occupied {
                                    background-color: #E0FFFF;
                                    color: white;
                                    }
                                `}</style>
                                
                            </div>
                            <div className='w55  b2  column cell-tamanho overflowY borda1'>
                                <div className='w100 h10 column center'>
                                    <div className='w100 column center'>
                                        <label className='fb'>SERVIÇOS AGENDADOS TOTAL {data.length}</label>
                                        
                                    </div>
                                </div>
                                <div className='w100 h10 m10'>
                                    {final}
                                </div>
                                
                            </div>


                        </div>     
                    </div>
                </div>
            </div>
            
        </section>
      </>
    )
}

