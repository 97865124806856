import { useEffect, useState } from 'react'
import axios from 'axios'
//COMPONENTES PARTES
//INPORTAÇOES DE ICONES E IMAGENS
import IconChecked from '../../acets/img_icon/checked.svg'
import MenuPainelAdmin from '../partils/AdminMenuPainel'
import IconNoChecked from '../../acets/img_icon/nochecked.svg'
import IconEdit from '../../acets/img_icon/edit.svg'
import AdminHeader from '../partils/AdminHeader'
import { useNavigate } from 'react-router-dom'
/*
id_financeiro	
id_tenancy	
status	
parcela	
mes	
valor	
desconto	
chave	
createdAt	
updatedAt
*/
export default function AdminFinanceiroTenancy(){
    const navigate = useNavigate();
    //CONGIGURAÇÕES=============================
    const config={
        pageName:'Financeiro',
        mainstring:'mes',
        url:'/api/financeirotenancy/',
        url2:'',
        search:'/api/financeirotenancy/search/',
        urlOptions1:'/api/produtocategoria',
        params:'',
        messegerError:'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
     //INICIANDO CICLO DE ESTADOS
     useEffect(()=>{
        rec();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    //STATES======================================
    ////    id_leads nome	telefone  email	porte	createdAt	updatedAt	
    const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
        nome: '',telefone:'', email: '',porte: ''
    }
    const [openNew,setOpenNew]=useState(false)// ABRIR TELA DE CADASTRAR E EDITAR
    const [values, setValues]=useState(initialValues) // INICIANDO UM STATE
    const [data, setData] = useState([])
    const[dataInput,setDataInput]=useState([{"id_produto_categoria":0}])
    //FUNÇÕES=====================================
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
        axios.get(config.url).then(resp=>{
            const invalid = {ERRO: 'token invalido 3'};
                if (JSON.stringify(resp.data[0]) === JSON.stringify(invalid)) {
                  navigate('/login');
                  window.location.reload(true);
                }
                else{
                  setData(resp.data);
                }
        })

        axios.get(config.urlOptions1).then(resp=>{
            setDataInput(resp.data)
            
        })
    }
    function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
        const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV
        setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
    }
    
    const exclui = function(id){//EXCLUI
        axios.delete(config.url + id).then(()=>{
            rec();
        })
    }
    function onSubmitSearch(ev){//PESQUISA
        ev.preventDefault();
        axios.post(config.search, values).then(respp=>{
            setData(respp.data)       
        })
    }
    //COMPONENTES DE LOOP==============================
/*id_financeiro	id_tenancy	status	parcela	mes	valor	desconto	chave	createdAt	updatedAt*/
//console.log(data)
    const tenancylocal = localStorage.getItem('ClienteTenancy')
    const final = data.map(
        namesString =>
            <div className='w100 h4 row ma5 between linha'  key={namesString.id_financeiro_tenancy }> 
                <div className='w8'><img src={IconChecked}/><img/></div>
                <div className='w2 cell-tamanho10'>{namesString.id_financeiro_tenancy}</div>
                <div className='w15 cell-tamanho80 fb'>{namesString.status}</div>
                <div className='w50 cell-tamanho cell-margin5 c4'>{namesString.mes}</div>
                <div className='w50 cell-tamanho cell-margin5 c4'>{namesString.chave}</div>
                <div className='w50 cell-tamanho cell-margin5 c4'>{namesString.desconto}</div>
                <div className='w10 cell-texto-p c4 cell-tamanho-metade'>{namesString.parcela.substring(0, 16)}</div>
                <div className='w10 cell-texto-p c4 cell-tamanho-metade'>{namesString.valor}</div>
                {tenancylocal==='starburguer'&&
                <button className='w5 btn04' onClick={()=>exclui(namesString.id_feature)}>X</button>
                }
            </div>
    )
    
    return(
        <>
        <section className='h90 column center b15 b30'>
            <AdminHeader props={config.pageName}/>
            <div className="h20 w90 posiabstop row between aligitensstart celular">
                <MenuPainelAdmin/>
                <div className="w70 row between cell-tamanho">
                    <div className="h20 w100 b2 cont-circ3 sombra column center">
                        <h5 className='w90'>Pesquisar {config.pageName}</h5>
                        <form className=' w90 row center' onSubmit={onSubmitSearch} autoComplete='off'>
                            <div className='w80'>
                                <input type="text" name="nome" onChange={onChange}/>
                            </div>
                            <div className='w20'>
                                <input type="submit" value="Pesquisar"/>
                            </div>
                        </form>
                        <div className='w90 h20 column'>
                            <label className='w100 ma5 fs1p3em'>{config.pageName} Listados(a)</label>
                            <div className='row between w100 h5 cell-none'>
                                <div className='w8'>Status</div>
                                <div className='w2 cell-tamanho10'>Id</div>
                                <div className='w15 cell-tamanho80'>Titulo</div>
                                <div className='w50 cell-tamanho cell-margin5 c4'>Descricao</div>
                                <div className='w10 cell-texto-p c4'>Previsão</div>
                                <div className='w10 cell-texto-p c4'>Realizado</div>
                                {tenancylocal==='starburguer'&&
                                    <div className='w5 cell-texto-p c4'>Ação</div>
                                }
                            </div>
                            {final}
                        </div>     
                    </div>
                </div>
            </div>
            <div className="h75 w100 b17">
               
            </div>
        </section>
      </>
    )
}

