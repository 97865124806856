
import axios from "axios";
import { Outlet } from "react-router-dom";
import Login from "../pages/Login";



export default function PrivateRouter(){
    const tenancylocal = localStorage.getItem('ClienteTenancy')
    axios.defaults.headers.common['id_tenancy'] = tenancylocal;
    

    const tlocal = localStorage.getItem('keyItemNameg')
    const useAuth = ()=>{
            const user = { loggadeIn:tlocal};
            return user && user.loggadeIn;
        }
    const isAuth = useAuth();


    return isAuth?<Outlet/>:<Login/>
    
}