import axios from 'axios';
import { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Menu from '../partils/Menu';
import LogoCardapio360 from '../../acets/img_icon/logo192.svg';
import TenancyColor from '../midllers/TenancyColor';

export default function Login() {
  const navigate = useNavigate();

  const config = useMemo(() => ({
    url: 'api/auth/'
  }), []);

  const tenancylocal = localStorage.getItem('ClienteTenancy');
  const [sucesse, setSucess] = useState(false);
  const [status, setStatus] = useState("");
  const initialValues = {
    email: '',
    senha: ''
  };
  const [values, setValues] = useState(initialValues);
const nivel = localStorage.getItem('usernivel');
  // Observa a variável 'sucesse' para redirecionar após sucesso
  useEffect(() => {
    if (sucesse) {
      
      console.log("aquiii login"+nivel) 
      
      navigate(nivel >= 20 ? "/painel" : "/adminpedido");
      window.location.reload(true)
    }
  }, [sucesse, navigate]);

  console.log("aquiii login sem nevel") 
  function onSubmit(ev) {
    ev.preventDefault();

    if (values.email.trim() === '' || values.senha.trim() === '') {
      alert('ATENÇÃO: Preencha o campo Email e/ou Senha!');
    } else {
      axios.post(config.url, values).then(res => {
        if (res.data?.ERRO === "Nome ou senha incorreto(s)") {
          alert("Usuário ou senha incorretos");
          setStatus("Senha errada");
        } else {
          if (res.data.token) {
            localStorage.setItem('keyItemNameg', res.data.token);
            localStorage.setItem('usernivel', res.data.a.nivel);
            const convert = JSON.stringify(res.data.a); 
            localStorage.setItem('ItemUsers', convert);  
            localStorage.setItem('ClienteTenancy', res.data.a.tenancyId); 
            localStorage.setItem('localcolor1', res.data.a.color1);
            setStatus("Carregando...");
            setSucess(true);  // Define sucesso após definir o token e dados no localStorage
           
          }
        }
      });
    }
  }

  function onchenge(ev) {
    const { name, value } = ev.target;
    setValues({ ...values, [name]: value });
  }

  return (
    <div className='w100 column center site-header-img-login h100'>
      <section className='h50 w40 cell-tamanho90 column around b-vidro cont-circ8'>
        <h5 className='fb w90 c1'>Login</h5>
        <div className=' h40 w90 cont-circ8 column center '>
          <label className='fs1p3em ma10b0 w100 c1'>{tenancylocal}</label> 
          <label className='fs08em c1 w100 ma25b0'>Login apenas para empresas ;D</label>
          <div className="w100">
            <form onSubmit={onSubmit}>
              <label className="w100 c1 fb">Email:</label>
              <input type="text" name="email" onChange={onchenge} placeholder="Ex:joao@gmail.com" style={{color:'#000'}} />
              <label className="w100 c1 fb">Senha:</label>
              <input type="password" name="senha" onChange={onchenge} placeholder="*****" style={{color:'#000'}} />
              <button className='w100 column center h5 ma10b0 pp cont-circ15' type="submit" style={{backgroundColor: TenancyColor().backgroundColor}}>Login</button>
            </form>
          </div>
        </div>
        <label className='fs08em ma10b0 w100 cen c1'>{tenancylocal} - Auto Métricas 2023</label> 
      </section>
    </div>
  );
}


/*
<a href="mailto:contato.cardapio360@gmail.com" className='fb h15 column center'>
        
          Solicitar Conta(Empresa)
        
      </a> 

*/