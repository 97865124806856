import { useEffect, useState } from 'react'
import axios from 'axios'
//COMPONENTES PARTES
//INPORTAÇOES DE ICONES E IMAGENS
import IconChecked from '../../acets/img_icon/checked.svg'
import MenuPainelAdmin from '../partils/AdminMenuPainel'
import IconNoChecked from '../../acets/img_icon/nochecked.svg'
import IconEdit from '../../acets/img_icon/edit.svg'
import IconExcluir from '../../acets/img_icon/ico_s_cancel.svg'
import AdminHeader from '../partils/AdminHeader'
import { useNavigate } from 'react-router-dom'
import TenancyColor from '../midllers/TenancyColor'

export default function AdminFeature(){
    const navigate = useNavigate();
    //CONGIGURAÇÕES=============================
    const config={
        pageName:'Feature',
        mainstring:'titulo',
        url:'/api/feature/',
        url2:'',
        search:'/api/feature/search/',
        urlOptions1:'/api/produtocategoria',
        params:'',
        messegerError:'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
     //INICIANDO CICLO DE ESTADOS
     useEffect(()=>{
        rec();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    //STATES======================================
    ////    id_leads nome	telefone  email	porte	createdAt	updatedAt	
    const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
        titulo: '',descricao:'', status: '',data_prevista: '',data_realizada: '',seguimento:''
    }
    const [openNew,setOpenNew]=useState(false)// ABRIR TELA DE CADASTRAR E EDITAR
    const [values, setValues]=useState(initialValues) // INICIANDO UM STATE
    const [data, setData] = useState([])
    //FUNÇÕES=====================================
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
        axios.get(config.url).then(resp=>{
            const invalid = {ERRO: 'token invalido 3'};
                if (JSON.stringify(resp.data[0]) === JSON.stringify(invalid)) {
                  navigate('/login');
                  window.location.reload(true);
                }
                else{
                  setData(resp.data);
                }
        })
    }
    function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
        const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV
        setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
    }
    
    const exclui = function(id){//EXCLUI
        axios.delete(config.url + id).then(()=>{
            rec();
        })
    }
    function onSubmitSearch(ev){//PESQUISA
        ev.preventDefault();
        axios.post(config.search, values).then(respp=>{
            setData(respp.data)       
        })
    }
    const editar = function(id){//
        setOpenNew(true)
        axios.get(config.url + id).then(respp=>{
            setValues(respp.data[0])
            })
    }
    function onSubmit(ev){//CADASTRAR NO BANCO DE DADOS, obs refatorado para tirar o if e else e usar o. ternario edit e cadastrar
        ev.preventDefault();
            axios[values.id_feature?'put':'post'](config.url,values).then(()=>{
                setOpenNew(false)
                setValues(initialValues);
                rec();
            })
    }
    //COMPONENTES DE LOOP==============================
    //    id_leads nome	telefone  email	porte	createdAt	updatedAt	
    const tenancylocal = localStorage.getItem('ClienteTenancy')
    const final = data.map(
        namesString =>
            <div className='w100 h4 row ma5 between linha'  key={namesString.id_feature}> 
                <div className='w8'><img src={IconChecked}/><img/></div>
                <div className='w2 cell-tamanho10'>{namesString.id_feature}</div>
                <div className='w15 cell-tamanho80 fb'>{namesString.titulo}</div>
                <div className='w50 cell-tamanho cell-margin5 c4'>{namesString.descricao}</div>
                <div className='w10 cell-texto-p c4 cell-tamanho-metade'>{namesString.data_prevista.substring(0, 16)}</div>
                <div className='w10 cell-texto-p c4 cell-tamanho-metade'>{namesString.data_realizada.substring(0, 16)}</div>
                {tenancylocal==='starburguer'&&
                <>
                <b className='' onClick={()=>exclui(namesString.id_feature)}><img src={IconExcluir}></img></b>
                <b onClick={()=>editar(namesString.id_feature)}> <img src={IconEdit}></img> </b>
                </>}
            </div>
    )
    
    return(
        <>
         {openNew&&           
            <div className='w100 h100 posifixedinicio column center z4' >
                <div className='w100 h100 posifixedinicio fundo-opaco z5' onClick={()=>(setOpenNew(false) & setValues(initialValues))}>
                </div>
                <div className='h20 w35 b2 cont-circ3 sombra column center z10 cell-tamanho90'>
                    <div className='w90 h20'>
                        <div className='w100'>
                            {values.id_feature?<h5>Editar {config.pageName}</h5>:<h5>Cadastrar novo(a) {config.pageName}</h5>  }
                        </div>
                        <form onSubmit={onSubmit} autoComplete='off'>
                            <div>
                                <label>titulo:</label>
                                <input type="text"  name="titulo"  onChange={onChange} value={values.titulo} autoComplete='off'></input>
                            </div>
                            <div>
                                <label>descricao:</label>
                                <input type="text"  name="descricao"  onChange={onChange} value={values.descricao}></input>
                            </div>
                            <div>
                                <label>data_prevista:</label>
                                <input type="date"  name="data_prevista"  onChange={onChange} value={values.data_prevista}></input>
                            </div>
                            <div>
                                <label>data_realizada:</label>
                                <input type="date"  name="data_realizada"  onChange={onChange} value={values.data_realizada}></input>
                            </div>
                            <div>
                                <label>seguimento:</label>
                                <input type="text"  name="seguimento"  onChange={onChange} value={values.seguimento}></input>
                            </div>
                            <div className='row between'>
                                <div className='w100'>
                                    <label>Status:</label>
                                    <select  name="status"  onChange={onChange} value={values.status} >
                                      <option value="1">Ativo</option>
                                      <option value="2">Desativo</option>
                                    </select>
                                </div>
                              
                            </div>
                            
                            
                           
                            <div className='cell-margin5'>
                                <input type="submit" value="Cadastrar"></input>
                            </div>
                        </form>
                        <div className='w100 row between'>
                            {values.id_produto?<button className='w25 btn04 cell-tamanho-metade' onClick={()=>exclui(values.id_produto)}>Excluir</button>:<label className='w40'>Sem mais opções</label>}
                            <button onClick={()=>(setOpenNew(false) & setValues(initialValues))} className="w40 ma10b0 btn05">Cancelar</button>
                        </div>
                        
                    </div>
                </div>
            </div>
        }
        <section className='h90 column center b15 b30'>
            <AdminHeader props={config.pageName}/>
            <div className="h20 w90 posiabstop row between aligitensstart celular">
                <MenuPainelAdmin/>
                <div className="w70 row between cell-tamanho">
                    <div className="h20 w100 b2 cont-circ3 sombra column center">
                        <div className='row between w90'>
                        <h5 className='w40'>Pesquisar {config.pageName}</h5>
                        {tenancylocal==='starburguer'&&
                            <div className='w20 h4 b10 c3 cont-circ8 row center pp cell-text-smile08' onClick={()=>(setOpenNew(true))&setValues(initialValues)} style={{backgroundColor: TenancyColor().backgroundColor}}>Add</div>
                        }
                        </div>
                        <form className=' w90 row center' onSubmit={onSubmitSearch} autoComplete='off'>
                            <div className='w80'>
                                <input type="text" name="nome" onChange={onChange}/>
                            </div>
                            <div className='w20'>
                                <input type="submit" value="Pesquisar"/>
                            </div>
                        </form>
                        <div className='w90 h20 column'>
                            <label className='w100 ma5 fs1p3em'>{config.pageName} Listados(a)</label>
                            <div className='row between w100 h5 cell-none'>
                                <div className='w8'>Status</div>
                                <div className='w2 cell-tamanho10'>Id</div>
                                <div className='w15 cell-tamanho80'>Titulo</div>
                                <div className='w50 cell-tamanho cell-margin5 c4'>Descricao</div>
                                <div className='w10 cell-texto-p c4'>Previsão</div>
                                <div className='w10 cell-texto-p c4'>Realizado</div>
                                {tenancylocal==='starburguer'&&
                                    <div className='w5 cell-texto-p c4'>Ação</div>
                                }
                            </div>
                            {final}
                        </div>     
                    </div>
                </div>
            </div>
            <div className="h75 w100 b17">
               
            </div>
        </section>
      </>
    )
}

