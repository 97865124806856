import { useEffect, useState } from 'react'
import axios from 'axios'
//INPORTAÇOES DE ICONES E IMAGENS
import IconChecked from '../../acets/img_icon/checked.svg'
import MenuPainelAdmin from '../partils/AdminMenuPainel'
import IconNoChecked from '../../acets/img_icon/nochecked.svg'
import IconEdit from '../../acets/img_icon/edit.svg'
import IcoStatus from '../partils/IcoStatus'
import SucessAlert from'../../components/partils/SucessAlert'
import TenancyColor from '../midllers/TenancyColor'
import IcoEditOpc from'../../acets/img_icon/editopc.svg'
import IcoBin from '../../acets/img_icon/bin.svg'
import AdminHeader from '../partils/AdminHeader'
export default function AdminProduto(){
    //CONGIGURAÇÕES=============================
    const config={
        pageName:'Produto',
        mainstring:'nome',
        url:'/api/produto_servico/',
        url2:'',
        search:'/api/produto_servico/search/',
        urlOptions1:'/api/dddd',
        
        params:'',
        messegerError:'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
     //INICIANDO CICLO DE ESTADOS
     useEffect(()=>{
        rec();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    //STATES======================================
//id_produto_servico	descricao	custo	preco	tipo	status	codigo	codigo_barras	tenancyId	createdAt	updatedAt
//id_produto_servico: '',	descricao: '',	custo: '',	preco: '',	tipo: '',	status: '',	codigo: '',	codigo_barras: '',
    const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
      id_produto_servico: '',	descricao: '',	custo: '',	preco: '',	tipo: '2',	status: '3',	codigo: '',	codigo_barras: '',quantidade:'1'
    }
    const initialValuesOpcionais={ // DEFININDO UMA CONST PARA O STATE INICIAL 
        id_produto:0,nome: '0',preco_final:'0',status: '1',preco_custo:'0'
    }
    const [openNew,setOpenNew]=useState(false)// ABRIR TELA DE CADASTRAR E EDITAR
    const [values, setValues]=useState(initialValues) // INICIANDO UM STATE
    const [valuesOpcionais, setValuesOpcionais]=useState(initialValuesOpcionais)
    const [data, setData] = useState([])
    const [datadash, setDatadash]=useState([])
    const [opemfiltro, setOpemfiltro]=useState(1)
    const [showModalVendas,setShowModalVendas]=useState(false)
    const [dataVendasProduto,setDataVendasProduto]=useState([])

    const [dataInput,setDataInput]=useState([{"id_produto_categoria":0}])
    const [opcionaisData,setOpcionaisData]=useState([])
    const [opcionaisState, setOpcionaisState] = useState({});
    const [sucessmodal, setSucessmodal] = useState(false);
    const [showModalSemana, setShowModalSemana] = useState(false);
    const [arraySemana, setArraySemana] = useState([])


    // Obter a data de hoje no formato 'YYYY-MM-DD'
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
    const day = String(today.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
    const formattedToday = `${year}-${month}-${Number(day) + 1}`;

    const [intervalfinalDate, setIntervalfinalDate] = useState(formattedToday);
    const [intervalinitialDate,setIntervalinitialDate]=useState('2024-02-01')

    const usernivel = localStorage.getItem('usernivel') 

    //FUNÇÕES=====================================
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
        axios.get(config.url).then(resp=>{
            setData(resp.data)
            //console.log(resp)
        })
        axios.get(`${config.url}dash/produto`).then(resp=>{
            setDatadash(resp.data)
            //console.log(resp)
        })
        
    }
    
    function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
        const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV filter ou desestruturação
        setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
    }
   
    function onSubmit(ev){//CADASTRAR NO BANCO DE DADOS, obs refatorado para tirar o if e else e usar o. ternario edit e cadastrar
      ev.preventDefault();
      if(values.descricao===''||values.preco===''){
        alert("Atencao, preencha todos os campos!")
         }else{
          axios[values.id_produto_servico?'put':'post'](config.url,values).then(()=>{
              setOpenNew(false)
              setValues(initialValues);
              rec();
          })
        }
  }
  function filterProdServ(id){
    axios.get(`${config.url}tipo/search/`+id).then(resp=>{
        setData(resp.data)
    })

  }
  const editar = function(id){//
      setOpenNew(true)
      axios.get(config.url + id).then(respp=>{
          setValues(respp.data[0])
          })
  }
    const exclui = function(id){
        axios.delete(config.url + id).then(()=>{
            rec();
            setOpenNew(false)
            setValues(initialValues)
        })
    }
    const excluiOpcionais = function(id){
        axios.delete('/api/produto_opcoes/' + id).then(()=>{
            rec();
            setOpenNew(false)
            setValues(initialValues)
        })
    }
    //console.log(valuesOpcionais)
    function onSubmitSearch(ev){//PESQUISA
        ev.preventDefault();
        axios.post(config.search, values).then(respp=>{
            setData(respp.data)       
        })
    }
    //Loop para exibir as vendas
    function listarVendasProduto (id){
        axios.get('/api/pivo_orcamento_produto/vendasproduto/'+id).then(respp=>{
            console.log(respp.data)
            setDataVendasProduto(respp.data)
            setShowModalVendas(true)
            setOpenNew(false)
        })
    }
    function listarVendasProdutoData (id){
        axios.get(`/api/pivo_orcamento_produto/vendasprodutodata/${id}/${intervalinitialDate}/${intervalfinalDate}`)
        .then(respp => {
            setDataVendasProduto(respp.data)
        })
    }
    //COMPONENTES DE LOOP==============================
    //console.log(data)
    const listarvendas = dataVendasProduto.map(strings=>
        <div className='row between' key={strings.id_pivo_orcamento_produto}>
            <label className='c1 fs08em w20'>{strings.createdAt.slice(2,10)}</label>
            <label className='c4 fs08em w20'>{strings.quantidade}x{strings.preco}</label>
            <label className='c4 fs08em w20'>R$:{strings.preco*strings.quantidade}</label>
            <label  style={{
                    background: `${
                          strings.orcamento.status ===1
                        ? "#68A652"
                        : strings.orcamento.status ===2
                        ? "#FFEC87"
                        : strings.orcamento.status ===3
                        ? "#B1FF95"
                        : strings.orcamento.status ===4
                        ? "#FFA487"
                        : "transparent"
                    }`,
                    width:'20%',
                    fontSize:'0.8em',
                    textAlign:'center'
                }}>{
                    
            strings.orcamento.status === 1
                ? "Em Serv."
                : strings.orcamento.status === 2
                ? "Pg Pend"
                : strings.orcamento.status === 3
                ? "Pago"
                : strings.orcamento.status === 4
                ? "Cancelado"
                : null
                }</label>
                <label className='w20 fs08em b28 cen'>{strings.orcamento.cliente.nome.slice(0,6)}</label>
        </div>
    )
    //const serverUrl = axios.defaults.baseURL
    const final = data.map(
        namesString =>
            <div className='w100 h4 row  between linha'  key={namesString.id_produto} 
                style={{
                    borderLeft: `6px solid ${
                          namesString.tipo ==="1"
                        ? "#00A7EF"
                        : namesString.tipo ==="2"
                        ? "#5847AD"
                        : namesString.tipo ==="3"
                        ? "#B1FF95"
                        : namesString.tipo ==="4"
                        ? "#FFA487"
                        : "transparent"
                    }`
                }}
            >  
                <div className='w50 row between'>    
                  <div className='w90 cell-tamanho90  text-smile-weight'>{namesString.descricao}</div>
                </div>
                <div className='w50 column between'>
                   
                    <div className='row between w100'>
                      <div className='w25  cell-texto-p c4'><label className='fw500 fs08em'>C:</label> {namesString.custo}</div>
                      <div className='w25  cell-texto-p c4'><label className='fw500 fs08em'>V:</label> {namesString.preco}</div>
                      <div className='w20  cell-texto-p c4 cell-none'><label className='fw500 fs08em'>L:</label> {String(namesString.preco-namesString.custo).substring(0, 5)}</div>
                      <div className='w15 cell-texto-p c4'><label className='fw500 fs08em'>Qnt:</label> {namesString.quantidade}</div>
                      <b onClick={()=>editar(namesString.id_produto_servico) } className='pp w5 row end'> <img src={IconEdit}></img></b>
                    </div>
                    <div className='row between w100 ma10b0 b12'>
                        {namesString.codigo&&<div className='cell-texto-p c4 fs08em'> Referencia:{namesString.codigo}</div>}
                        {namesString.codigo_barras&&<div className='w50 fs08em cell-tamanho cell-margin5 c4'>Cod.Barras:{namesString.codigo_barras}</div>}
                        
                    </div>
                    
                </div>
            </div>
    )
    const totalvendas = dataVendasProduto.reduce((total, item) => total + parseFloat(item.preco*item.quantidade), 0).toFixed(2)
    const totalcusto = dataVendasProduto.reduce((total, item) => total + parseFloat(item.produto_servico.custo*item.quantidade), 0).toFixed(2)
    const Quantidade = dataVendasProduto.reduce((total, item) => total +item.quantidade,0)
      
      const confirmExclusao = (id) => {
        const confirmar = window.confirm("Tem certeza que deseja excluir este item?");
        if (confirmar) {
          excluiOpcionais(id);
        }
      }
     
    //console.log(arraySemana)

    //descricao: '',	custo: '',	preco: '',	tipo: '',	status: '',	codigo: '',	codigo_barras: '',
    return(
        <>
        {showModalVendas&&
        <div className='w100 h150 posiabstop0  column center z17 overflow' >
            <div className='w100 h150 posifixedinicio pcb50 fundo-opaco z5' onClick={()=>(setShowModalVendas(false) & setValues(initialValues))}>
            </div>
            <div className='h20 w35 b2 cont-circ3 sombra column between z10 cell-tamanho90'>
                <div className='w90 h20 column between'>
                    <h5 className='w100'>Vendas realizadas do produto</h5>
                    
                    <div className="w100 b2 c3 b2 sombra-small flex row center ma10b0">
                        <label className="w95 ma5 ">Selecione o intervalo de datas para obter os dados</label> 
                        <div className="w95 row between">
                           <div className="w33">
                              <label className="fs08em">Início</label>
                              <input 
                                 type="date"
                                 onChange={(e) => setIntervalinitialDate(e.target.value)}
                              />
                           </div>
                           <div className="w33">
                              <label className="fs08em">Fim</label>
                              <input 
                                 type="date"
                                 onChange={(e) => setIntervalfinalDate(e.target.value)}
                              />
                           </div>
                           <div className="w25">
                              <label>Ação</label>
                              <button className="w95" onClick={()=>listarVendasProdutoData(values.id_produto_servico)}>Ir</button>
                           </div>
                           <label className="fs08em ma5">
                              {"De: "+intervalinitialDate + " até " + intervalfinalDate} 
                           </label>
                        </div>
                        
                     </div>

                    <label className='pcb10 w100 fb'>{dataVendasProduto[0]?.produto_servico.descricao.slice(0,30)}..</label>
                    <div className='w100 overflow '>
                        {listarvendas}
                    </div>
                    <div className='w100 row between ma10b0'>
                                <label className='w100 ma10b0 fs08em'>Dados sobre as vendas deste produto</label>
                                <div className='w30 column center b28 pcb10'>
                                    <label className='cen fs06em fb'>Vendidos</label>
                                    <div className='row center'>
                                        <label>{Quantidade}</label>
                                    </div>
                                   
                                </div>
                                <div className='w30 column center b28 pcb10'>
                                    <label className='cen fs06em fb'>Venda</label>
                                    <div className='row center'>
                                        <label className='fs06em'>R$:</label>
                                        <label>{totalvendas}</label>
                                    </div>
                                </div>
                                <div className='w30 column center b28 pcb10'>
                                    <label className='cen fs06em fb'>Lucro das vendas</label>
                                    <div className='row center'>
                                        <label className='fs06em'>R$:</label>
                                        <label>{totalvendas-totalcusto}</label>
                                    </div>
                                </div>
                                
                            </div>
                    <div className='row w100 between pcb10'>
                        <label>Sem mais ações</label>
                        <button onClick={()=>(setShowModalVendas(false) & setValues(initialValues))}>Fechar</button>
                    </div>
                    
                </div>
            </div>
        </div>
        }
        {openNew&&           
            <div className='w100 h150 posiabstop0  column center z17 overflow' >
                <div className='w100 h150 posifixedinicio pcb50 fundo-opaco z5' onClick={()=>(setOpenNew(false) & setValues(initialValues))}>
                </div>
                <div className='h20 w35 b2 cont-circ3 sombra column center z10 cell-tamanho90'>
                    <div className='w90 h20'>
                        <div className='w100'>
                            {values.id_produto_servico?<h5>Editar {config.pageName}</h5>:<h5>Cadastrar novo(a) {config.pageName}</h5>  }
                        </div>
                        <form onSubmit={onSubmit} autoComplete='off' encType="multipart/form-data">
                            <div className='ma5'>
                                <label className='c4 fs09em'>Campos obrigatórios <span className='c9'>*</span> </label>
                            </div>
                            
                            <div>
                                <label>Descrição: <span className='c9'>*</span></label>
                                <input type="text"  name="descricao"  onChange={onChange} value={values.descricao} autoComplete='off'></input>
                            </div>
                            <div className='row between w100'>
                            {values.id_produto_servico&&
                                <div className='w30'>
                                    <label>Qnt:</label>
                                    <input type="number"  name="quantidade"  onChange={onChange} value={values.quantidade}></input>
                                </div>
                            }
                                <div className='w30'>
                                    <label>Custo unit.:</label>
                                    <input type="number"  name="custo"  onChange={onChange} value={values.custo}></input>
                                </div>
                                <div className='w30'>
                                    <label>Venda<span className='c9'>*</span></label>
                                    <input type="number"  name="preco"  onChange={onChange} value={values.preco}></input>
                                </div>
                                <div className='w30'>
                                    <label>tipo <span className='c9'>*</span></label>
                                    <select  name="tipo"  onChange={onChange} value={values.tipo}>
                                        <option value="2">Produto</option>
                                        <option value="1">Servico</option>
                                    </select>
                                </div>
                                
                            </div>
                            <div className='row between'>
                                <div className='w30'>
                                    <label>Status <span className='c9'>*</span></label>
                                    <select  name="status"  onChange={onChange} value={values.status}>
                                        <option value="3">Ativado</option>
                                        <option value="6">Desativado</option>
                                        <option value="5">Promoção</option>
                                    </select>
                                </div>
                                
                                <div className='w65'>
                                    <label>codigo:</label>
                                    <input type="text"  name="codigo"  onChange={onChange} value={values.codigo}></input>
                                </div>
                            </div>
                            
                            <div>
                                <label>codigo_barras:</label>
                                <input type="number"  name="codigo_barras"  onChange={onChange} value={values.codigo_barras}></input>
                            </div>
                            {values.quantidade&&
                            <div className='w100 row between ma10b0'>
                                <label className='w100 ma10b0'>Dados sobre o estoque deste produto</label>
                                <div className='w30 column center b28 pcb10'>
                                    <label className='cen fs06em fb'>Em estoque</label>
                                    <div className='row center'>
                                        <label className='fs06em'>R$:</label>
                                        <label>{values.quantidade*values.preco}</label>
                                    </div>
                                   
                                </div>
                                <div className='w30 column center b28 pcb10'>
                                    <label className='cen fs06em fb'>Custo em estoque</label>
                                    <div className='row center'>
                                        <label className='fs06em'>R$:</label>
                                        <label>{values.quantidade*values.custo}</label>
                                    </div>
                                </div>
                                <div className='w30 column center b28 pcb10'>
                                    <label className='cen fs06em fb'>Lucro do estoque</label>
                                    <div className='row center'>
                                        <label className='fs06em'>R$:</label>
                                        <label>{values.quantidade*values.preco-values.quantidade*values.custo}</label>
                                    </div>
                                </div>
                                
                            </div>}
                            <div className='cell-margin5'>
                                <button type="submit" className='w100'>{values.id_produto_servico ? 'Atualizar' : 'Cadastrar'}</button>
                            </div>
                        </form>
                        <div className='w100 row between'>
                            <label className='fs08em pcb10 c4'>Para <label className='fs08em fb'>Excluir</label> o produto ele deve ser exluido de todos os orçamentos. Operação recomendada para produtos recem cadastrados.</label>
                            {values.id_produto_servico?
                            <button className='w25 btn04 fs06em fb' onClick={()=>exclui(values.id_produto_servico)}>Excluir</button>:
                            <label className='w40 fs08em'>Sem mais opções</label>}
                           {usernivel>=20&&
                                 <button onClick={()=>listarVendasProduto(values.id_produto_servico)} className='w30 fs08em'>Vendas</button>
                           }
                            <button onClick={()=>(setOpenNew(false) & setValues(initialValues))} className="w30 ma10b0 btn05 fs08em">Cancelar</button>
                        </div>
                        
                    </div>
                </div>
            </div>
        }
        
        <section className='h100 row between b31'>
            
            <MenuPainelAdmin/>
            <div className="h100 w80 cell-tamanho row center celular">
                    
                    <div className="w90 row between cell-tamanho celular">
                        <div className="h100 w100 cont-circ3  column cell-tamanho cell-paddingcb10">
                        <h5 className='w90'>Pesquisar {config.pageName}</h5>
                        <form className=' w90 row center' onSubmit={onSubmitSearch} autoComplete='off'>
                            <div style={{backgroundColor: TenancyColor().backgroundColor}} className='btn10' onClick={()=>(setOpenNew(true))&setValues(initialValues)} >Add</div>
                            <div className='w60'>
                                <input type="text" name="descricao" onChange={onChange} placeholder=' Search'/>
                            </div>
                            <div className='w20'>
                                <input type="submit" value="Pesquisar" style={{backgroundColor: TenancyColor().backgroundColor}} className='cell-text-smile08'/>
                            </div>
                        </form>
                        <div className='w90 h20 column '>
                            <label className='w100 ma5'>Dados sobre o estoque de Produtos atual</label>
                            <div className='w100 h20 borda1 b2 ma10b0 row around'>
                                <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5 cell-none">
                                    <label className=" ma10b0 c1 fb cen">Quantidade</label>
                                    {/*<label className="fs06em cen c1">Fiados</label>*/}
                                    <div className="row">
                                    <label className="fs06em"> </label>
                                    <label className=" fb h5 column center c4">{datadash.quantidade}</label>
                                    </div>
                                </div>
                                <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5">
                                    <label className=" ma10b0 c1 fb cen">Venda Futura</label>
                                    {/*<label className="fs06em cen c1">Fiados</label>*/}
                                    <div className="row">
                                    <label className="fs06em">R$: </label>
                                    <label className=" fb h5 column center c4">{datadash.venda_futura}</label>
                                    </div>
                                </div>
                                <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5">
                                    <label className=" ma10b0 c1 fb cen">Custo Total</label>
                                    {/*<label className="fs06em cen c1">Fiados</label>*/}
                                    <div className="row">
                                    <label className="fs06em">R$: </label>
                                    <label className=" fb h5 column center c4">{datadash.custo_total}</label>
                                    </div>
                                </div>
                                <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5">
                                    <label className=" ma10b0 c1 fb cen">Lucro Futuro</label>
                                    {/*<label className="fs06em cen c1">Fiados</label>*/}
                                    <div className="row">
                                    <label className="fs06em">R$: </label>
                                    <label className=" fb h5 column center c4">{datadash.venda_futura-datadash.custo_total}</label>
                                    </div>
                                </div>
                                
                            </div>
                            
                            
                            <div className='row between w100 h10 ma10b0'>
                                <div className='row start ' >
                                    <label className='w100 ma10b0'>Selecione o filtro</label>
                                    <div className='p10 borda1 pp' style={opemfiltro === 1 ? { background: '#0792E3', color: '#fff' } : { background: '#fff', color: '#000' }} onClick={()=>{rec();setOpemfiltro(1)}}>Todos</div>
                                    <div className='p10 borda1  pp ml5 ' style={opemfiltro === 2 ? { background: '#0792E3', color: '#fff' } : { background: '#fff', color: '#000' }} onClick={()=>{filterProdServ(2);setOpemfiltro(2)}}>Produtos</div>
                                    <div className='p10 borda1  pp ml5' style={opemfiltro === 3 ? { background: '#0792E3', color: '#fff' } : { background: '#fff', color: '#000' }} onClick={()=>{filterProdServ(1);setOpemfiltro(3)}}>Serviço</div>
                                </div>
                               
                            </div>
                            
                            <div className='w100 h20 column'>
                                <label className='w100 ma5 ma10b0'>{config.pageName} Listados(a)</label>
                                
                                    {final}
                                
                            </div>  
                            
                        </div>     
                    </div>
                </div>
            </div>
            
          
        </section>
      </>
    )
}

